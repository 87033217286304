import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { APIPost } from "../../../API/APIMethod";
import "./styles.css";

function WXCISCTestMinePage() {
  const [resultList, setResultList] = useState([]);
  const [showList, setShowList] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    console.log('componentDidMount');
    if (global.wxOpenId) {
      requestData({ openid: global.wxOpenId });
    }
  }, []);

  const requestData = (params) => {
    const path = '/getciscwxusertestanswerlistbyopenid/';
    const onSuccess = (res) => {
      const data = res?.data;
      console.log('data', data);
      if (data.ciscanswerinfolist.length > 0) {
        setResultList(data.ciscanswerinfolist);
        setShowList(true);
      }
    };
    APIPost(path, params).then(onSuccess);
  };

  const handleClick = (item) => {
    const queryParams = new URLSearchParams();
    queryParams.append('id', item.id);

    const path = {
      pathname: '/ciscqatestreport',
      search: queryParams.toString()
    };

    console.log('reportxxx', path);
    return path;
  };

  const handleClickLinkToTestPage = () => {
    const queryParams = new URLSearchParams();
    const path = {
      pathname: '/ciscqatest',
      search: queryParams.toString()
    };
    console.log('reportxxx', path);
    return path;
  };

  const handleGotoTestPage = () => {
    navigate('/ciscqatest');
  };

  const handleChange = (event) => {
    global.wxOpenId = event.target.value;
  };

  const handleSubmitEmail = () => {
    console.log('handleSubmitEmail');
    requestData({ openid: global.wxOpenId });
  };

  if (!showList) {
    return (
      <div>
        <label htmlFor="inputField">输入框：</label>
        <input
          type="text"
          id="inputField"
          onChange={handleChange}
        />
        <button onClick={handleSubmitEmail}>提交</button>
      </div>
    );
  }

  return (
    <div className="viewCISCMinePage">
      <div className="textQuestion">Report List</div>
      {resultList.map((item) => (
        <div className="textResult">
          <Link to={handleClick(item)} className="textResult">{item.createtime}</Link>
        </div>
      ))}
      <div onClick={handleGotoTestPage} className="textStart">开始测试</div>
      {/* <div><Link to={handleClickLinkToTestPage()} className="textStart">开始测试</Link></div> */}
    </div>
  );
}

export default WXCISCTestMinePage;