import React, { useEffect, useState } from "react";
import "./styles.css";
import { APIPost } from "../../../API/APIMethod";
import { useNavigate } from "react-router-dom";

const WXCISCTestQAPage = () => {
  const [currentQAIndex, setCurrentQAIndex] = useState(0);
  const [AnswerList, setAnswerList] = useState([]);
  const Separator = '、';

  const [depressionScore, setDepressionScore] = useState(0);
  const [anxietyScore, setAnxietyScore] = useState(0);
  const [pressureScore, setPressureScore] = useState(0);
  const [pressureFrom, setPressureFrom] = useState('');
  const [pressureReleaseWay, setPressureReleaseWay] = useState('');
  const [schoolSatisfaction, setSchoolSatisfaction] = useState('');
  const [specialitySatisfaction, setSpecialitySatisfaction] = useState('');
  const [overallSatisfaction, setOverallSatisfaction] = useState('');

  // let depressionScore = 0;
  // let anxietyScore = 0;
  // let pressureScore = 0;
  // let pressureFrom = '';
  // let pressureReleaseWay = '';
  // let schoolSatisfaction = '';
  // let specialitySatisfaction = '';
  // let overallSatisfaction = '';
  let QAList = [
    {
      No: 1,
      isMulti: 0,
      Q: '您的年龄是：',
      A: [
        '18 岁以下',
        '19-22 岁',
        '23-26 岁',
        '26-30 岁',
        '31 岁及以上',
      ]
    },
    {
      No: 2,
      isMulti: 0,
      Q: '您在国外已经留学了：',
      A: [
        '1 年及以内',
        '2-3 年',
        '4-5 年',
        '6 年及以上',
      ]
    },
    {
      No: 3,
      isMulti: 0,
      Q: '您正在留学攻读的学位是：',
      A: [
        '中学',
        '本科',
        '硕士',
        '博士',
      ]
    },
    {
      No: 4,
      isMulti: 1,
      Q: '您正在学习的专业（方向）是（可选 1-2 项）：',
      A: [
        '社科语言类',
        '艺术类',
        '心理类',
        '医学/护理类',
        '人力资源',
        '经济/金融/会计',
        '商务/管理/MBA',
        '法律类',
        '数理化生/理科类',
        '天文/地理/历史',
        '计算机软硬件/VR/AI',
        '其它专业',
      ]
    },
    {
      No: 5,
      isMulti: 0,
      Q: '您目前留学的国家或地区是？',
      A: [
        '英国',
        '德国',
        '法国',
        '美国',
        '加拿大',
        '澳大利亚',
        '新西兰',
        '日本',
        '韩国',
        '新加坡',
        '中国香港',
        '其他国家/地区',
      ]
    },
    {
      No: 6,
      isMulti: 0,
      Q: '您喜欢您现在的学校吗？',
      A: [
        '非常喜欢',
        '比较喜欢',
        '一般吧',
        '不太喜欢',
        '说不好',
      ]
    },
    {
      No: 7,
      isMulti: 0,
      Q: '您喜欢您现在的专业吗？',
      A: [
        '非常喜欢',
        '比较喜欢',
        '一般吧',
        '不太喜欢',
        '说不好',
      ]
    },
    {
      No: 8,
      isMulti: 0,
      Q: '总体感觉，您觉得自己学习的压力有多大？（100 分为最大，0 分为没有压力）',
      A: [
        '100 分及以上（超负荷）',
        '90-99 分',
        '80-90 分',
        '60-80 分',
        '50-60 分',
        '50 分以下',
      ]
    },
    {
      No: 9,
      isMulti: 1,
      Q: '您感觉自己的压力主要来源于以下哪些方面（可选 1-3 项）：',
      A: [
        '学业太重',
        '作业量太大',
        '导师/老师压力',
        '父母期待',
        '未来就业',
        '语言障碍/学习能力跟不上',
        '同学关系紧张/淡漠',
        '能否考试通过',
        '能否拿到学位',
        '时间太紧张',
        '经济状况',
        '其它/说不清',
      ]
    },
    {
      No: 10,
      isMulti: 1,
      Q: '您感觉自己情绪不好的时候可能最受下面哪些方面影响（可选 1-3 项）：',
      A: [
        '学业压力',
        '没有好朋友/孤独',
        '身体不适',
        '导师/老师关系',
        '睡眠不好',
        '恋爱问题',
        '父母关系',
        '饮食不适',
        '别人不能理解我',
        '生活不习惯/水土不服',
        '经济状况',
        '其它/说不清',
      ]
    },
    {
      No: 11,
      isMulti: 0,
      Q: '我觉得自己比以前更容易紧张或起急：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 12,
      isMulti: 0,
      Q: '我会无缘无故感到害怕：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 13,
      isMulti: 0,
      Q: '我容易心烦意乱或心跳加快：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 14,
      isMulti: 0,
      Q: '我觉得一切都好，也不会发生什么不幸：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 15,
      isMulti: 0,
      Q: '我因为头痛（头晕）、颈痛或背痛而苦恼：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 16,
      isMulti: 0,
      Q: '我感觉很容易衰弱和疲乏：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 17,
      isMulti: 0,
      Q: '我因为胃疼和消化不良而苦恼：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 18,
      isMulti: 0,
      Q: '我很容易入睡并且一夜睡得很好：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 19,
      isMulti: 0,
      Q: '我感到情绪低落，沮丧、郁闷：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 20,
      isMulti: 0,
      Q: '我吃的跟以前一样多：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 21,
      isMulti: 0,
      Q: '我感觉要哭或想哭：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 22,
      isMulti: 0,
      Q: '我与他人接触时感到愉快：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 23,
      isMulti: 0,
      Q: '我会无缘无故感到疲劳：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 24,
      isMulti: 0,
      Q: '我的头脑跟以前一样清楚：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 25,
      isMulti: 0,
      Q: '我坐卧不安，难以保持平静：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 26,
      isMulti: 0,
      Q: '我觉得自己是个有用的人，有人需要我：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 27,
      isMulti: 0,
      Q: '我感觉如果我消失了别人会过得更好：',
      A: [
        '没有/偶尔有',
        '有时有',
        '经常有',
        '总是如此',
      ]
    },
    {
      No: 28,
      isMulti: 0,
      Q: '我对我目前的学习和生活状况整体满意度为：',
      A: [
        '非常满意',
        '满意',
        '基本满意',
        '一般',
        '不太满意',
      ]
    },
    {
      No: 29,
      isMulti: 0,
      Q: '您参加大学社团/其它活动的情况：',
      A: [
        '经常参加/每周都有活动',
        '偶尔参加/每学期 1-2 次',
        '没有参加过',
      ]
    },
    {
      No: 30,
      isMulti: 0,
      Q: '您在留学期间交友的情况',
      A: [
        '有 2 个以上交心的朋友',
        '有 1-2 个不错的朋友',
        '朋友不少，但不怎么交心',
        '几乎没有朋友/算不上有朋友',
        '独来独往，没有朋友',
      ]
    },
    {
      No: 31,
      isMulti: 0,
      Q: '您当下的婚恋状况：',
      A: [
        '有相爱的恋人',
        '有固定的交往对象',
        '单身状态/单相思/分手了',
        '已婚',
      ]
    },
    {
      No: 32,
      isMulti: 0,
      Q: '您感觉您跟父母的关系是：',
      A: [
        '相互理解，关系融洽',
        '他们不太理解我，关系还可以',
        '相互不理解，关系一般',
        '他们非常不理解我，不怎么沟通',
        '说不好',
      ]
    },
    {
      No: 33,
      isMulti: 1,
      Q: '当您遇到挫折/失败或挑战时，您会（可选 1-3 项）',
      A: [
        '寻求朋友/同学/老师的帮助',
        '寻求父母/长辈的支持',
        '自责和消沉',
        '尽量逃避/压抑',
        '积极面对，寻求解决办法',
        '随遇而安/随其自然',
        '感觉运气好差，倒霉的总是我',
        '没什么，大不了从头再来',
        '其它',
      ]
    },
    {
      No: 34,
      isMulti: 1,
      Q: '您觉得压力大或者情绪不好的时候会更容易做下面哪些事情（可选 1-3 项）：',
      A: [
        '找人宣泄/倾诉',
        '看电影/去酒吧',
        '做饭吃/大吃一顿',
        '听音乐/冥想放松',
        '找心理咨询师',
        '运动/健身',
        '旅游/户外活动',
        '逛街/购物',
        '上网/玩游戏',
        '学校社群活动',
        '一个人闷着/忍着/睡觉',
        '其它',
      ]
    },
    {
      No: 35,
      isMulti: 0,
      Q: '如果您遇到较大的情绪困扰或心理状态不好，您会愿意接受专业的心理咨询/教练辅导吗？',
      A: [
        '非常愿意',
        '愿意',
        '不太愿意',
        '说不好',
      ]
    },
    {
      No: 36,
      isMulti: 0,
      Q: '您对于留学生可以获得心理支持和帮助的途径：',
      A: [
        '清楚了解',
        '不太清楚，但应该能查到',
        '曾经求助过',
        '不知道，不关心',
      ]
    },
  ];

  console.log('global.wxOpenId qa', global.wxOpenId);

  let navigate = useNavigate();

  const handleSelect = (text) => {
    let tempList = [...AnswerList];
    const QA = QAList[currentQAIndex];

    if (QA.isMulti === 0) {
      tempList[currentQAIndex] = new Set();
      tempList[currentQAIndex].add(text);
    } else {
      if (tempList[currentQAIndex]) {
        if (tempList[currentQAIndex].has(text)) {
          tempList[currentQAIndex].delete(text);
        } else {
          tempList[currentQAIndex].add(text);
        }
      } else {
        tempList[currentQAIndex] = new Set();
        tempList[currentQAIndex].add(text);
      }
    }

    setAnswerList(tempList);
  };

  const formatNumber = (count) => {
    if (count < 10) {
      return '0' + count;
    }
    return count;
  };

  const getNowTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = formatNumber(now.getMonth() + 1);
    const date = formatNumber(now.getDate());
    const hours = formatNumber(now.getHours());
    const minutes = formatNumber(now.getMinutes());
    const seconds = formatNumber(now.getSeconds());

    console.log(`当前时间为：${year}-${month}-${date} ${hours}:${minutes}:${seconds}`);
    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
  };

  const checkIfNext = () => {
    return !!AnswerList[currentQAIndex];
  };

  const handleSubmit = () => {
    // Add your submit logic here
    console.log('Answerlist', AnswerList);
    console.log('currentQAIndex', currentQAIndex);
    const submitAnxietyScore = anxietyScore * 3;
    const submitDepressionScore = depressionScore * 2.5;

    console.log('AnxietyScore 最低24', submitAnxietyScore);
    console.log('DepressionScore 最低25', submitDepressionScore);
    console.log('压力指数', pressureScore);
    console.log('压力来源', pressureFrom);
    console.log('解压方式', pressureReleaseWay);
    console.log('整体满意度', overallSatisfaction);
    console.log('学习满意度', schoolSatisfaction);
    console.log('专业满意度', specialitySatisfaction);

    const params = {
      anxietyScore: submitAnxietyScore,
      wxOpenId: global.wxOpenId,
      depressionScore: submitDepressionScore,
      pressureScore: Array.from(pressureScore).join(Separator),
      pressureFrom: Array.from(pressureFrom).join(Separator),
      pressureReleaseWay: Array.from(pressureReleaseWay).join(Separator),
      overallSatisfaction: Array.from(overallSatisfaction).join(Separator),
      schoolSatisfaction: Array.from(schoolSatisfaction).join(Separator),
      specialitySatisfaction: Array.from(specialitySatisfaction).join(Separator),
      answer1: Array.from(AnswerList[0]).join(Separator),
      answer2: Array.from(AnswerList[1]).join(Separator),
      answer3: Array.from(AnswerList[2]).join(Separator),
      answer4: Array.from(AnswerList[3]).join(Separator),
      answer5: Array.from(AnswerList[4]).join(Separator),
      answer6: Array.from(AnswerList[5]).join(Separator),
      answer7: Array.from(AnswerList[6]).join(Separator),
      answer8: Array.from(AnswerList[7]).join(Separator),
      answer9: Array.from(AnswerList[8]).join(Separator),
      answer10: Array.from(AnswerList[9]).join(Separator),
      answer11: Array.from(AnswerList[10]).join(Separator),
      answer12: Array.from(AnswerList[11]).join(Separator),
      answer13: Array.from(AnswerList[12]).join(Separator),
      answer14: Array.from(AnswerList[13]).join(Separator),
      answer15: Array.from(AnswerList[14]).join(Separator),
      answer16: Array.from(AnswerList[15]).join(Separator),
      answer17: Array.from(AnswerList[16]).join(Separator),
      answer18: Array.from(AnswerList[17]).join(Separator),
      answer19: Array.from(AnswerList[18]).join(Separator),
      answer20: Array.from(AnswerList[19]).join(Separator),
      answer21: Array.from(AnswerList[20]).join(Separator),
      answer22: Array.from(AnswerList[21]).join(Separator),
      answer23: Array.from(AnswerList[22]).join(Separator),
      answer24: Array.from(AnswerList[23]).join(Separator),
      answer25: Array.from(AnswerList[24]).join(Separator),
      answer26: Array.from(AnswerList[25]).join(Separator),
      answer27: Array.from(AnswerList[26]).join(Separator),
      answer28: Array.from(AnswerList[27]).join(Separator),
      answer29: Array.from(AnswerList[28]).join(Separator),
      answer30: Array.from(AnswerList[29]).join(Separator),
      answer31: Array.from(AnswerList[30]).join(Separator),
      answer32: Array.from(AnswerList[31]).join(Separator),
      answer33: Array.from(AnswerList[32]).join(Separator),
      answer34: Array.from(AnswerList[33]).join(Separator),
      answer35: Array.from(AnswerList[34]).join(Separator),
      answer36: Array.from(AnswerList[35]).join(Separator),
      createtime: getNowTime(),
    }
    requestSubmit(params);
  };

  const handleBacktoMinePage = () => {
    // window.location.href = 'http://192.168.1.16:3000/ciscqatestmine';
    // history.push('/ciscqatestmine');
    // navigate('/ciscqatestmine');
    navigate(-1);
  };

  const requestSubmit = (params) => {
    const path = '/setciscwxusertestanswer/';
    const onSuccess = (res) => {
      const data = res?.data;
      console.log('data', data);
      handleBacktoMinePage();
    };

    APIPost(path, params).then(onSuccess);
  };

  const handleNext = () => {
    if (!checkIfNext()) {
      return;
    }

    //anxietyScore 焦虑分数
    if (currentQAIndex >= 10 && currentQAIndex <= 17) {
      if (currentQAIndex === 13 || currentQAIndex === 17) {
        if (AnswerList[currentQAIndex].has('没有/偶尔有')) {
          // anxietyScore = anxietyScore + 4;
          setAnxietyScore(anxietyScore + 4)
        } else if (AnswerList[currentQAIndex].has('有时有')) {
          // anxietyScore = anxietyScore + 3;
          setAnxietyScore(anxietyScore + 3)
        } else if (AnswerList[currentQAIndex].has('经常有')) {
          // anxietyScore = anxietyScore + 2;
          setAnxietyScore(anxietyScore + 2)
        } else {
          // anxietyScore = anxietyScore + 1;
          setAnxietyScore(anxietyScore + 1)
        }
      } else {
        if (AnswerList[currentQAIndex].has('没有/偶尔有')) {
          // anxietyScore = anxietyScore + 1;
          setAnxietyScore(anxietyScore + 1)
        } else if (AnswerList[currentQAIndex].has('有时有')) {
          // anxietyScore = anxietyScore + 2;
          setAnxietyScore(anxietyScore + 2)
        } else if (AnswerList[currentQAIndex].has('经常有')) {
          // anxietyScore = anxietyScore + 3;
          setAnxietyScore(anxietyScore + 3)
        } else {
          // anxietyScore = anxietyScore + 4;
          setAnxietyScore(anxietyScore + 4)
        }
      }
      console.log('anxietyScore', anxietyScore);
    }

    //depressionScore 抑郁分数
    if (currentQAIndex >= 17 && currentQAIndex <= 26) {
      if (currentQAIndex == 17 || currentQAIndex == 19 || currentQAIndex == 21 || currentQAIndex == 23 || currentQAIndex == 25) {
        if (AnswerList[currentQAIndex].has('没有/偶尔有')) {
          setDepressionScore(depressionScore + 4);
        } else if (AnswerList[currentQAIndex].has('有时有')) {
          setDepressionScore(depressionScore + 3);
        } else if (AnswerList[currentQAIndex].has('经常有')) {
          setDepressionScore(depressionScore + 2);
        } else {
          setDepressionScore(depressionScore + 1);
        }
      } else {
        if (AnswerList[currentQAIndex].has('没有/偶尔有')) {
          setDepressionScore(depressionScore + 1);
        } else if (AnswerList[currentQAIndex].has('有时有')) {
          setDepressionScore(depressionScore + 2);
        } else if (AnswerList[currentQAIndex].has('经常有')) {
          setDepressionScore(depressionScore + 3);
        } else {
          setDepressionScore(depressionScore + 4);
        }
      }
      console.log('depressionScore', depressionScore);
    }

    if (currentQAIndex == 26) {
      if (AnswerList[currentQAIndex] == '没有/偶尔有') {
        console.log('ok')
      } else {
        console.log('需要报警')
      }
    }

    // 压力模块
    if (currentQAIndex == 7) {
      // pressureScore = AnswerList[currentQAIndex];
      setPressureScore(AnswerList[currentQAIndex])
    }

    if (currentQAIndex == 8) {
      // pressureFrom = AnswerList[currentQAIndex];
      setPressureFrom(AnswerList[currentQAIndex])
    }

    if (currentQAIndex == 33) {
      // pressureReleaseWay = AnswerList[currentQAIndex];
      setPressureReleaseWay(AnswerList[currentQAIndex])
    }

    //生活满意度
    if (currentQAIndex == 5) {
      // schoolSatisfaction = AnswerList[currentQAIndex];
      setSchoolSatisfaction(AnswerList[currentQAIndex])
    }

    if (currentQAIndex == 6) {
      // specialitySatisfaction = AnswerList[currentQAIndex];
      setSpecialitySatisfaction(AnswerList[currentQAIndex])
    }

    if (currentQAIndex == 27) {
      // overallSatisfaction = AnswerList[currentQAIndex];
      setOverallSatisfaction(AnswerList[currentQAIndex])
    }


    // console.log('currqaindex', currentQAIndex);


    if (currentQAIndex === QAList.length - 1) {
      console.log('提交数据，返回上一页，跳转到报告');
      handleSubmit();
      return;
    }

    setCurrentQAIndex(currentQAIndex + 1);
  };

  const renderXuanxiang = (text) => {
    let isSelected = false;
    const currentAnswer = AnswerList[currentQAIndex];

    if (currentAnswer?.has?.(text)) {
      isSelected = true;
    }

    const textAnswerClassName = isSelected ? 'textAnswerSelect' : 'textAnswer';

    return (
      <div className={textAnswerClassName} onClick={() => handleSelect(text)}>
        {text}
      </div>
    );
  };

  const currentQA = QAList[currentQAIndex];

  return (
    <div className="viewCISCQAPage">
      <div className="textQuestion">{currentQA.Q}</div>
      {currentQA.A.map((item) => renderXuanxiang(item))}
      <div className={checkIfNext() ? "nextButton" : "nextButtonGray"} onClick={handleNext}>
        {currentQAIndex === QAList.length - 1 ? '提交' : '下一题'}
      </div>
    </div>
  );
};

export default WXCISCTestQAPage;