// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { useEffect, useState, Component } from "react";
import "./styles.css";
import { APIPost, APIPostForm } from "../../../API/APIMethod";


export default class WXCISCTestReportPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    // const queryParams = new URLSearchParams(location.search);
    // const id = queryParams.get('id');
    console.log('report id', id);

    this.requestReport({id: id})

    console.log('testreport wxopenid',global.wxOpenId)


  }

  requestReport = (params) => {
    // const path = '/pay/public/createCheckoutSession';
    const path = '/getciscwxusertestanswerbyid/';
    const onSuccess = (res) => {
        const data = res?.data;
        console.log('data', data);
       if (data) {
          this.setState({
            data: data.ciscanswerinfo,
          })
       }
    }
    APIPost(path, params)
        .then(onSuccess)
  }

  render() {

    const {data} = this.state;

    const anxietyScore = parseInt(data?.anxietyScore);

    console.log('xxxx', anxietyScore);

    let anxietyLevel = 1;

    let anxietyText = '';
    if (anxietyScore <= 46) {
      anxietyLevel = 1;
      anxietyText = '您的焦虑得分显示无焦虑症状。表明您现在心情稳定，心理相对健康，请继续保持当前状态，用心学习，快乐生活。'
    } else if (anxietyScore <=49) {
      anxietyLevel = 1;
      anxietyText = '您的焦虑得分显示基本无焦虑症状，但您的得分接近轻度焦虑的边界值，需要引起您的重视，建议您及时调节心理状态，保持心理健康。用心学习，快乐生活。'
    } else if (anxietyScore <=59) {
      anxietyLevel = 2;
      anxietyText = '您的得分显示轻微至轻度焦虑。有时自感内心比较烦乱，与以往比较，容易出现紧张和害怕，对未来有莫名的担忧。有时会出现心跳快，心慌。也可能有身体上的不舒服的感觉，诸如头痛、头晕或者头和背有紧绷感，有可能有胃部不适、食欲减退，经常做梦等症状，睡眠有些不好，易惊醒。请您调整心情，放松身心，放慢节奏，规律作息，必要时请向专业人士寻求心理咨询或心理教练支持。'
    } else if (anxietyScore <=69) {
      anxietyLevel = 3;
      anxietyText = '您的焦虑自评得分显示您有中度焦虑症状。您现在自感内心比较烦乱，很容易出现紧张和害怕，非常担心未来会有不好的事情发生。经常心跳快，心慌，身体也常常有不舒服的感觉，诸如头痛、头晕或者头和背有紧绷感，胃部不适、食欲有减退，经常做梦等，睡眠困难，易惊醒。请您多多关爱自己，多考虑如何平衡学业与生活，让自己的身心放松下来。如有必要，建议您及时向专业人士寻求心理咨询或心理教练支持。'
    } else {
      anxietyLevel = 4;
      anxietyText = '您的焦虑自评得分显示您有重度焦虑症状。您经常感到内心非常烦乱，很容易出现紧张和害怕，躁动不安，对未来有莫名的强烈恐惧。明显感觉到心跳快，心慌，身体总会出现不舒服的感觉，诸如头痛、头晕或头和背有紧绷感，胃部不适、食欲减退，经常做梦等，睡眠很困难，易惊醒。请您引起注意，强烈建议您及时向专业人士寻求心理治疗。'
    }

    //yiyu
    const depressionScore = parseInt(data?.depressionScore);

    let depressionLevel = 1;

    let depressionText = '';
    if (depressionScore <= 49) {
      depressionLevel = 1;
      depressionText = '您的抑郁得分显示无抑郁。表明您现在心情稳定，心理相对健康，请继续保持当前状态，轻松学习，快乐生活。'
    } else if (depressionScore <=52) {
      depressionLevel = 1;

      depressionText = '您的得分显示基本无抑郁状态，但您的得分接近轻度抑郁状态的边界值，需要引起您的重视，建议您及时调节心理状态，保持心理健康。轻松学习，快乐生活。'
    } else if (depressionScore <=62) {
      depressionLevel = 2;

      depressionText = '您的抑郁自评得分显示您有轻度抑郁状态。自认为有时会出现情绪低落，或者感到常常没有愉快的心情。这种低落情绪会持续一段时间。同时，兴趣感也逐步减少，对以往喜欢的事情也变得无兴趣，说话没有以前多，对生活上也感到空虚，没劲，缺乏意义。经常感到疲倦，有时食欲不振，胃部不适，偶尔会出现紧张、不安的情绪反应。请您调整心情，放松身心，增加户外活动和人际交往，必要时请向专业人士寻求心理咨询或心理教练支持。'
    } else if (depressionScore <=72) {
      depressionLevel = 3;

      depressionText = '您的抑郁自评得分显示您有中度抑郁状态。自认为情绪低落比较明显，经常感到心情很不愉快，持续时间也比较长。兴趣明显下降，对以往非常喜欢的东西变得毫无兴趣，常有生活没意义的想法，常认为自己无用、无能、缺少价值感，甚至会哭泣、流泪、发脾气，经常有疲倦感，食欲不振，睡眠不好、早醒。请您多多关爱自己，多参加户外活动和增加人际交往，平衡学业与生活，让自己渐渐恢复活力。同时，建议您及时向专业人士寻求心理咨询或心理教练支持。'
    } else {
      depressionLevel = 4;

      depressionText = '您的抑郁自评得分显示您有重度抑郁症状。您近来情绪非常低落，感觉自己毫无生气，丝毫没有愉快的感觉，经常产生无助感，或者绝望感，自怨自责。经常有活着太累，想解脱、想消失的念头出现。经常哭泣或者整日愁眉苦脸，说话明显减少，活动量也明显减少，对一切都不敢兴趣，甚至连最喜欢的事物也不喜欢了，出现明显的睡眠障碍，入睡困难或者早醒。请您务必引起重视，强烈建议您及时向专业人士寻求心理治疗。'
    }


    //压力
    let pressureScoreText1 = '';
    let pressureScoreText2 = '';
    let pressureLevel = 1;
    if (data?.pressureScore == '100 分及以上（超负荷）') {
      pressureLevel = 5;
      pressureScoreText1 = '您目前感到学习压力很大，感觉几乎不能承受了。您的精神非常紧张，身心非常疲惫，情绪波动很大，烦躁不安。经常忘事，常有拖延的现象，内心纠结矛盾，对未来觉得很迷茫。身体也出现了一些不适症状，甚至会有生病的迹象，需要引起您的高度重视。'
      pressureScoreText2 = '建议您及时寻求学校心理老师或者专业心理咨询师的帮助和支持，尽快调整身心状态。'
    } else if (data?.pressureScore == '90-99 分') {
      pressureLevel = 5;
      pressureScoreText1 = '您目前感到学习压力很大，感觉几乎不能承受了。您的精神非常紧张，身心非常疲惫，情绪波动很大，烦躁不安。经常忘事，常有拖延的现象，内心纠结矛盾，对未来觉得很迷茫。身体也出现了一些不适症状，甚至会有生病的迹象，需要引起您的高度重视。'
      pressureScoreText2 = '建议您及时寻求学校心理老师或者专业心理咨询师的帮助和支持，尽快调整身心状态。'
    } else if (data?.pressureScore == '80-90 分') {
      pressureLevel = 4;
      pressureScoreText1 = '您目前感到学业压力相当大，常感身心紧张，比较烦躁，情绪起伏较大。做事常有拖延，有时会忘事。心中偶有矛盾纠结，或心绪繁乱，对未来感到有些迷茫。有时会感到身体不适，比如头痛、胸闷、肚子痛等，甚至会有少许的脱发，需要得到您的重视。'
      pressureScoreText2 = '建议您合理安排学习时间，适当减少负担，保持规律的生活作息。同时可以尝试进行适度的运动和放松活动，如深呼吸、冥想或散步，来缓解身心压力。如果压力持续，建议及时寻求学校心理老师或专业心理咨询师的帮助，来更好地调整状态并找到适合的解决方案。'
    } else if (data?.pressureScore == '60-80 分') {
      pressureLevel = 3;
      pressureScoreText1 = '您目前感到学业压力较大，心理有些不适，有紧张感。有时心情低落，或心绪烦乱。有轻微的挫败感，对于平常喜欢的事物似乎也提不起兴趣了，需要引起您的重视。'
      pressureScoreText2 = '建议您适当放慢节奏，给自己一些时间休息和调整，尝试做一些能够让您放松的活动，如听音乐、运动或与朋友交流。保持规律的生活作息有助于缓解情绪波动。如果情况没有好转，建议与学校心理老师或专业心理咨询师沟通，寻求更多支持。'
    } else if (data?.pressureScore == '50-60 分') {
      pressureLevel = 2;
      pressureScoreText1 = '您目前感到学业压力较合适，表明您现在心情相对稳定，请您根据自己的情况随时调整心情，学习与娱乐搭配进行，能让学习更高效。'
      pressureScoreText2 = '建议您继续保持目前的良好状态，适当分配学习与休息时间，确保身心平衡。可以尝试设定适当的目标，让学习变得更有动力。同时，保持与朋友、家人沟通交流，分享您的感受与想法，以保持积极的心态。'
    } else if (data?.pressureScore == '50 分以下') {
      pressureLevel = 1;
      pressureScoreText1 = '您目前感到学业压力不大，表明您现在心情较稳定，或有动力不足的情况。请您继续保持积极的状态，轻松学习，快乐生活。'
      pressureScoreText2 = '建议您保持现有的平衡状态，同时可以尝试给自己设定一些小目标，增加学习的挑战性和趣味性。如果偶尔感到动力不足，尝试通过参与感兴趣的活动或与朋友交流来寻找新的动力源，保持积极的学习态度与生活热情。'
    }


    let pressureFromText = data?.pressureFrom;
    if (data?.pressureFrom?.includes('学业太重')) {
      //
    } if (data?.pressureFrom?.includes('作业量太大')) {
      //
    } if (data?.pressureFrom?.includes('导师/老师压力')) {
      pressureFromText=pressureFromText.replace('导师/老师压力', '学老师给予的压力')
    } if (data?.pressureFrom?.includes('父母期待')) {
      pressureFromText=pressureFromText.replace('父母期待', '父母的期待')
    } if (data?.pressureFrom?.includes('未来就业')) {
      pressureFromText=pressureFromText.replace('未来就业', '未来的就业问题')
    } if (data?.pressureFrom?.includes('语言障碍/学习能力跟不上')) {
      pressureFromText=pressureFromText.replace('语言障碍/学习能力跟不上', '课业太难，有点跟不上')
    } if (data?.pressureFrom?.includes('同学关系紧张/淡漠')) {
      pressureFromText=pressureFromText.replace('同学关系紧张/淡漠', '与同学关系一般甚至不太好')
    } if (data?.pressureFrom?.includes('能否考试通过')) {
      pressureFromText=pressureFromText.replace('能否考试通过', '是否能通过考试')
    } if (data?.pressureFrom?.includes('能否拿到学位')) {
      pressureFromText=pressureFromText.replace('能否拿到学位', '是否能拿到学位')
    } if (data?.pressureFrom?.includes('时间太紧张')) {
      pressureFromText=pressureFromText.replace('时间太紧张', '时间太不够用、太紧张')
    } if (data?.pressureFrom?.includes('经济状况')) {
      pressureFromText=pressureFromText.replace('经济状况', '经济情况')
    } if (data?.pressureFrom?.includes('其它/说不清')) {
      pressureFromText=pressureFromText.replace('其它/说不清', '其他说不清的方面')
    }


    let pressureReleaseWayText = data?.pressureReleaseWay;
    if (data?.pressureReleaseWay?.includes('找人宣泄/倾诉')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('找人宣泄/倾诉', '找人聊聊倾诉一下')
    } if (data?.pressureReleaseWay?.includes('看电影/去酒吧')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('看电影/去酒吧', '去看电影或去酒吧')
    } if (data?.pressureReleaseWay?.includes('做饭吃/大吃一顿')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('做饭吃/大吃一顿', '做顿饭或大吃一顿')
    } if (data?.pressureReleaseWay?.includes('听音乐/冥想放松')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('听音乐/冥想放松', '听音乐或冥想放松')
    } if (data?.pressureReleaseWay?.includes('找心理咨询师')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('找心理咨询师', '找心理咨询师聊聊')
    } if (data?.pressureReleaseWay?.includes('运动/健身')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('运动/健身', '去运动健身')
    } if (data?.pressureReleaseWay?.includes('旅游/户外活动')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('旅游/户外活动', '去旅游或户外活动')
    } if (data?.pressureReleaseWay?.includes('逛街/购物')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('逛街/购物', '去逛街购物')
    } if (data?.pressureReleaseWay?.includes('上网/玩游戏')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('上网/玩游戏', '去上网或打打游戏')
    } if (data?.pressureReleaseWay?.includes('学校社群活动')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('学校社群活动', '参加学校社群活动')
    } if (data?.pressureReleaseWay?.includes('一个人闷着/忍着/睡觉')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('一个人闷着/忍着/睡觉', '一个人呆着或睡觉')
    } if (data?.pressureReleaseWay?.includes('其它')) {
      pressureReleaseWayText=pressureReleaseWayText.replace('其它', '做其他事')
    }


    let pressureText = pressureScoreText1 + '从测评得知，您的学习压力主要来源于' + pressureFromText + '；您在压力大或情绪不好的时候更倾向于' + pressureReleaseWayText + '。' + pressureScoreText2;
   

    //心理状态
    let overallSatisfaction = data?.overallSatisfaction;
    let schoolSatisfaction = data?.schoolSatisfaction;
    let specialitySatisfaction = data?.specialitySatisfaction;

    let satisfactionText = '';

    if (overallSatisfaction == '非常满意' || overallSatisfaction == '满意' ) {
      if (schoolSatisfaction == '非常喜欢' || schoolSatisfaction == '比较喜欢') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对目前的学习和生活状况非常满意，且对学校和专业也有很高的认同感。这表明您已经很好地适应了环境，学业进展顺利。为了保持这种状态，您可以尝试参加一些新的校园社团或校外活动，增加生活的丰富度。同时，维持规律的生活习惯，如保持充足的睡眠和健康饮食，有助于提升学习效率和生活质量。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '虽然您对整体的学习和生活感到满意，也比较喜欢学校，但对专业存在一定的困惑或不确定感。可以考虑通过与学术导师或职业规划顾问交流，明确未来的发展方向。生活上，适时安排一些兴趣活动或短期旅行，能够帮助您释放学习压力，并为您提供更多思考和探索的空间。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对生活和学校整体感到满意，但对专业的兴趣似乎不太高。建议您尝试通过选修课或加入专业相关的实践项目来增强对专业的了解和兴趣。生活方面，您可以通过健身、阅读等方式丰富业余时间，这不仅能提升生活质量，还能帮助您更好地调节情绪，维持健康的身心状态。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '虽然您对学校和生活感到满意，但对专业兴趣较低。建议您与专业指导老师或职业规划师讨论是否可以调整学习方向，或者发掘专业中的其他领域来激发您的兴趣。生活方面，建议您积极参与学校的课外活动或社交活动，通过建立人际网络来增强生活的丰富性和满足感。'
        }
      } else if(schoolSatisfaction == '说不好') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对生活整体感到满意，且对专业有很大的兴趣，但对学校的感受比较模糊。建议您通过积极参与校园活动和多与师生互动，提升对校园环境的归属感。生活方面，您可以多安排一些娱乐活动，保持社交和生活的多样性，帮助您在忙碌的学业中获得放松和平衡。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '您对生活状态整体满意，但对学校和专业没有明确的感受或认同。可以通过多与同学、老师交流，参加更多的校园活动来寻找归属感和兴趣点。生活上，建议您多尝试新的兴趣爱好，如艺术、运动等，这有助于丰富生活体验，增强生活的充实感和幸福感。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '虽然您对生活整体满意，但对学校和专业没有太强的认同感。您可以尝试通过加入社团或实习项目来增加对专业和校园的归属感和参与感。生活方面，保持健康的作息和适度的运动非常重要，这有助于减轻压力，提升您的情绪稳定性和生活满意度。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '虽然您对整体生活感到满意，但对学校和专业的兴趣较低。建议您尝试通过改变学习方式或参与一些跨学科活动，寻找新的学习动力。生活上，您可以多参与社区活动或短途旅行，结识更多志趣相投的朋友，这有助于提高生活的愉悦感。'
        }
      } else if(schoolSatisfaction == '一般吧') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对生活感到满意，且对专业充满兴趣，但对学校的喜爱程度一般。您可以通过加入专业相关的社团或校外实践来增加对学校的参与感，进而增强对校园的认同。生活方面，可以通过参加一些兴趣班或社交活动，丰富您的生活体验，增加幸福感。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '虽然您对整体生活感到满意，但对学校和专业的感受比较模糊。建议您积极探索校园资源，如参加各类讲座、学术活动，或与学术顾问讨论，寻找更明确的方向。生活方面，可以多安排休闲时间和娱乐活动，帮助您平衡学习与生活，减轻压力。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对生活较满意，但对学校和专业的兴趣并不强烈。可以考虑通过与学术导师交流或参加一些专业讲座，帮助自己发现新的兴趣点。生活上，建议您适当调整作息，增加锻炼时间，这有助于提高您的整体生活质量和情绪稳定性。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '您对生活满意，但对学校和专业的兴趣较低。可以考虑通过与专业导师探讨其他学习方向，或者发掘现有专业的隐藏优势。生活上，您可以通过参加运动或艺术类活动来调节身心，提升生活的趣味性与幸福感。'
        }
      }  else if(schoolSatisfaction == '不太喜欢') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对生活状态满意，且对专业有很高的兴趣，但对学校的感受较低。可以通过与师生互动，参与更多校园内外活动来提升对学校的好感。同时，建议您保持积极的生活态度，尝试平衡好学业与娱乐，丰富您的生活体验，提升整体的幸福感。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '您对生活满意，但对学校和专业的兴趣不高或不确定。建议您与学术顾问讨论是否有调整专业方向的可能，或者参与更多跨学科的活动来拓宽视野。生活上，可以通过安排短期旅行或与朋友外出活动，来丰富您的生活体验和提升生活的愉悦感。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '虽然您对生活总体满意，但对学校不太喜欢，且对专业兴趣一般。建议您思考是否有其他学校或专业更符合您的兴趣和目标。同时，生活上您可以尝试通过参加新的兴趣班或组织，丰富您的日常活动，提升生活的满足感和快乐感。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '尽管您对生活总体感到满意，但对学校和专业的认同感较低。建议您考虑是否需要在学术方向或学校选择上做出调整。生活方面，您可以多参加一些能让您放松的活动，如运动、音乐、社交活动等，帮助您减轻压力，提升生活的愉悦度和满足感。'
        }
      }
    } else if (overallSatisfaction == '基本满意' || overallSatisfaction == '一般' ) {
      if (schoolSatisfaction == '非常喜欢' || schoolSatisfaction == '比较喜欢') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对生活整体感到基本满意，并且对学校和专业有很高的兴趣。虽然您在生活上可能遇到一些小困扰或不完全满足，建议您通过丰富业余生活，比如参加社团活动、培养兴趣爱好，来提高整体的幸福感。同时，维持良好的学习习惯将帮助您在学业上取得进一步的进步。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '虽然您对生活状态感到一般，但对学校有较好的感受，然而对专业方向有些不确定。建议您多与专业导师沟通，了解不同职业发展路径，并参与一些与专业相关的活动或项目。生活上，保持积极的社交和放松方式，能够减轻您对未来的不安感，提升生活满意度。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对生活整体感到一般，对学校有较高的满意度，但对专业兴趣不大。建议您尝试从不同角度重新认识当前专业，或者探索与专业相关的跨学科课程。生活上，您可以通过参加体育活动或社交聚会，增加生活的活力，改善对当前生活的感受。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '尽管您对生活感到基本满意，并且对学校感到喜欢，但对专业的兴趣较低。建议您与学术顾问或职业导师讨论可能的课程调整或未来的专业发展方向。生活上，多参加一些自己感兴趣的休闲活动或短途旅行，能够帮助您获得更多生活上的乐趣和放松。'
        }
      } else if(schoolSatisfaction == '说不好') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对生活状态感到一般，对学校没有明确的感受，但对专业充满兴趣。可以通过参与更多与专业相关的社团或校外实践来进一步加深对专业的热爱，同时提升对学校环境的认同感。生活方面，您可以多安排与朋友的聚会或其他娱乐活动，增加生活的丰富性。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '您对生活状态感到一般，对学校和专业的感受都不确定。可以考虑通过参与校园活动或加入学习小组，逐步培养对学校和专业的归属感。生活上，适当安排个人时间和放松活动，如读书、运动等，有助于缓解压力，提升生活的积极性。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对生活状态感到一般，对学校没有特别的喜好，对专业的兴趣也比较一般。建议您积极参与学校的课外活动，扩大人际交往圈，尝试找到新的兴趣点。生活上，保持规律的作息，并安排一些自己感兴趣的娱乐活动，能帮助您提升生活满意度。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '您对生活状态感到一般，并且对学校和专业的兴趣都较低。可以尝试通过社交活动或校外实践来寻找新的学习动力，或者与辅导员讨论调整学习方向的可能性。生活上，适当安排一些短期旅行或社交活动，帮助您改善生活状态。'
        }
      } else if(schoolSatisfaction == '一般吧') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对生活感受一般，但对专业有较大的兴趣。可以通过多参与专业相关的项目、实习或讲座，提升对专业的兴趣，并通过此途径加深对学校的认同感。生活上，建议您尝试新的兴趣活动，或加入一些让您放松的社交圈子，丰富您的生活体验。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '您对生活感受一般，对学校和专业都没有特别的喜好。可以通过参与学校的各种课外活动，尝试与同学建立更多联系，增强对学校的归属感。生活上，可以尝试寻找新的娱乐方式，如运动、艺术等，以改善当前的生活状态。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对目前的学习生活整体感受一般，无论是学校还是专业都没有特别的喜好。建议您尝试通过参加学校社团或参与社会实践活动，来激发对生活和学习的新兴趣。同时，合理安排时间进行放松和休息，帮助您更好地应对压力和挑战。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '您对生活感受一般，对学校和专业的兴趣都不高。可以考虑与辅导员探讨是否需要调整课程安排，或者通过参加校外实践活动来发掘新的兴趣点。生活上，可以尝试运动、读书等自我提升的活动来平衡生活与学业，缓解当前的压力。'
        }
      }  else if(schoolSatisfaction == '不太喜欢') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对生活感到一般，对学校不太喜欢，但对专业有很高的兴趣。建议您更多地参与专业相关的活动或实习项目，通过这些方式来增强对学习的热情。同时，生活上可以尝试通过结交新朋友或参加娱乐活动来提高对学校和生活的满意度。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '您对生活感到一般，对学校和专业的兴趣不高。建议您通过参与课外活动或与学术顾问交流来寻找新的学习和生活动力。生活上，可以尝试短期旅行或户外活动，帮助自己放松并从中找到新的兴趣点和生活乐趣。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对生活感到一般，对学校不太喜欢，专业兴趣也不高。可以与学术顾问探讨是否需要调整学习路径，或考虑是否有其他学校更符合您的期望。生活上，增加社交活动或参加一些新鲜事物，有助于减轻对学校和生活的不满感。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '您对目前的生活感到一般，对学校和专业都不太满意。建议您认真思考是否需要做出学业上的调整，或者寻找新的发展方向。生活方面，可以通过参加一些能让自己愉悦的活动，如社交、运动或旅行，来缓解压力，提升生活质量。'
        }
      }
    } else if (overallSatisfaction == '不太满意' ) {
      if (schoolSatisfaction == '非常喜欢' || schoolSatisfaction == '比较喜欢') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '虽然您对生活感到不太满意，但您对学校和专业有较强的兴趣。您可以通过增加生活中的积极活动，比如运动、艺术或社交活动，来平衡生活的不满。同时，保持与老师和同学的良好关系，专注于学术上的发展，或许能为您带来更多满足感。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '尽管您对生活感到不太满意，并且对专业存在一定的困惑，但您对学校的感受还是比较积极的。建议您多与专业导师沟通，明确自己的学术方向，同时尝试调整日常生活安排，多关注自己在生活中感到不满的部分，如社交、作息等，寻找方法来提升幸福感。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对生活状态不太满意，虽然您喜欢学校，但对专业兴趣较低。可以考虑与学术导师讨论可能的专业调整，或者发掘现有专业中的兴趣点。同时，在生活上多安排一些放松的活动，如户外运动或与朋友的聚会，有助于缓解压力和提升整体的生活满意度。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '您对生活的满意度较低，虽然对学校有较高的认同感，但对专业的兴趣不足。建议您与辅导员讨论，看看是否有调整专业方向的可能，同时通过加入校园活动和拓展兴趣爱好来提升生活的丰富性，从而找到平衡和幸福感。'
        }
      } else if(schoolSatisfaction == '说不好') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '虽然您对当前的生活感到不满，并对学校的感受较为模糊，但您对专业有较大的兴趣。可以通过多参与专业相关的社团活动或实习机会，进一步加深对专业的理解，同时也提升对学校环境的认同感。生活方面，建议您多进行休闲娱乐活动，缓解生活压力。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '您对当前的生活状态和学习感受都不满意，说明生活和学业上的许多方面未能达到您的期望。建议您先进行一次自我反思，找出生活和学业中最令您困扰的部分，然后与辅导员或心理咨询师沟通，寻找适合的改善方法。同时，多参与一些自己感兴趣的活动，缓解生活压力，提升幸福感。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对目前的生活感到不满，对学校没有明确的喜好，且对专业的兴趣一般。可以尝试通过多与导师和同学交流，了解更多与专业相关的机会，重新激发兴趣。生活上，可以通过参加社交活动或爱好兴趣班，丰富生活内容，减轻压力和负面情绪。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '您对生活和学习都感到不满，且对学校和专业都没有太大的兴趣。可以考虑与学术顾问探讨是否调整课程或学习计划，同时寻找新的生活方式，如加入健身房或进行户外活动，以改善身心状态，提升对生活的满意度。'
        }
      } else if(schoolSatisfaction == '一般吧') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对生活不太满意，但对学校和专业有较强的兴趣。建议您通过多参与学术活动或与老师同学互动，提升对学校的归属感。同时，生活上可以尝试通过改善作息、加入社交圈子或参加户外活动来调整心情，增加生活的快乐感。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '您对生活感到不满意，对学校和专业的认同感也不强。建议您主动寻找与学校相关的实践机会，提升对学校的归属感。生活方面，可以通过短期旅行或加入兴趣班，调整生活节奏和心情，从而改善生活满意度。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对目前的生活不太满意，并且对学校和专业的兴趣都比较一般。可以考虑通过加入学校社团或寻找校外实践机会，增加生活的多样性。生活方面，建议您设定一些个人目标，如健康饮食、锻炼或培养新兴趣，这有助于改善生活满意度。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '您对生活状态不太满意，且对学校和专业的兴趣不高。可以考虑与学术导师讨论课程调整的可能性，或者探索新的学习方式。生活上，您可以通过参加自己感兴趣的活动或培养新的爱好，来增强生活的积极性，并减轻当前的压力。'
        }
      }  else if(schoolSatisfaction == '不太喜欢') {
        if (specialitySatisfaction == '非常喜欢' || specialitySatisfaction == '比较喜欢') {
          satisfactionText = '您对生活不太满意，对学校的喜爱度较低，但对专业有较强的兴趣。建议您更多地参与与专业相关的项目或实践活动，通过这些活动提升对学校的归属感。同时，生活上可以多安排一些自己感兴趣的休闲活动，帮助缓解生活中的压力，提升生活质量。'
        } else if (specialitySatisfaction == '说不好') {
          satisfactionText = '您对生活和学习都感到不满意，对学校和专业的感受较为负面。可以尝试与辅导员讨论是否有可能调整课程或学习方向，同时也可以多参与社交活动，拓展人际圈子，寻找更多的生活乐趣，减少压力。'
        } else if (specialitySatisfaction == '一般吧') {
          satisfactionText = '您对生活不太满意，对学校的感受也不高，且对专业兴趣一般。建议您与学术顾问探讨是否有其他课程或专业更适合您的兴趣。同时，生活上可以尝试通过参加社交活动、与朋友聚会或发展新爱好，来丰富您的生活体验，缓解压力。'
        } else if (specialitySatisfaction == '不太喜欢') {
          satisfactionText = '您对当前的生活、学校和专业都不太满意，这表明您可能需要进行较大的调整。建议您认真思考当前的学习和生活方向是否符合自己的需求，必要时可以与导师或心理咨询师沟通，讨论可能的调整方案。同时，生活上可以考虑进行新的尝试，如旅行、运动或参加兴趣班，帮助您找到新的兴趣点。'
        }
      }
    }

    let summaryText = '';
    let summaryTipsText = ''

    if (anxietyLevel == 1) {
      if (depressionLevel == 1) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。总体来看，您的心理状态非常良好，没有焦虑和抑郁症状，且压力较小，说明您对当前的生活和学业处理得当。';
          summaryTipsText = '尽管您的心理状况非常稳定，建议您保持当前的健康生活方式，避免过度放松可能带来的惰性。可以定期参加一些能激发灵感的活动，如参加讲座、读书会或培养新的爱好。与此同时，保持充足的睡眠、规律的运动和健康的饮食，能让您的身心继续处于最佳状态。不要忘记与朋友、家人保持联系，分享生活中的点滴，这将增强您的幸福感和情感支持网络。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。您的心理状态非常健康，适度的压力可能在推动您实现个人目标和激励学习上起到了积极作用。';
          summaryTipsText = '适度的压力是成长的动力，但也需要保持良好的压力管理。建议您设定合理的学习和生活目标，避免过度追求完美导致压力累积。每天留出时间进行放松活动，如深呼吸练习、冥想或轻松的散步。此外，偶尔给自己安排一些愉快的活动，如参加社交活动或短途旅行，能帮助您更好地平衡生活与学习。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明您感受到了较大的压力。虽然您没有焦虑和抑郁症状，但长期承受较大的压力可能会影响您的身心健康，需要及时关注和调节。';
          summaryTipsText = '当您感觉到压力较大时，重要的是及时识别压力的来源，并采取有效的减压措施。建议您尝试通过规律的运动来释放压力，例如跑步、瑜伽或健身。此外，与植物亲近也是一种很好的减压方式，可以在家中放置绿植或在公园散步，让自然帮助您放松心情。如果压力持续增加，您可以考虑与朋友或心理咨询师交流，分享内心的感受并获得支持。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明您感受到了非常大的压力。尽管您的心理状态总体良好，但过大的压力可能会给您的身心带来负担，需要及时进行调整。';
          summaryTipsText = '高度的压力可能会影响您的生活质量，建议您每天抽出时间进行深呼吸练习、冥想或练习渐进性肌肉放松法，以减轻压力对身体的影响。此外，尽可能保证充足的睡眠是非常重要的，这有助于恢复身心活力。您也可以尝试与亲密的朋友或家人交流，分享您的压力源，获得情感支持。如果压力持续不减，建议您及时寻求心理咨询帮助，避免长期压力对健康造成的影响。'
        } 
      } else if (depressionLevel == 2) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。您没有焦虑的症状，但可能在情绪上感受到轻度抑郁，需关注情绪的波动并进行适当调节。';
          summaryTipsText = '轻度的抑郁情绪可能来自生活中的某些困扰或压力源。建议您每天留出时间做一些让自己感到放松和愉快的活动，如听音乐、阅读或散步。与朋友分享感受，保持社交联系，也能有效提升情绪。如果发现自己对生活中的事情失去兴趣，建议参加一些能够激发激情的活动，重新找到乐趣。如果抑郁情绪持续或加重，请及时寻求心理支持。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。您没有焦虑的症状，但可能在情绪上感受到轻度抑郁，需关注情绪的波动并进行适当调节。';
          summaryTipsText = '轻度的抑郁情绪可能来自生活中的某些困扰或压力源。建议您每天留出时间做一些让自己感到放松和愉快的活动，如听音乐、阅读或散步。与朋友分享感受，保持社交联系，也能有效提升情绪。如果发现自己对生活中的事情失去兴趣，建议参加一些能够激发激情的活动，重新找到乐趣。如果抑郁情绪持续或加重，请及时寻求心理支持。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。虽然您没有焦虑，但较大的压力和轻度抑郁可能会对您的情绪和身体造成影响。';
          summaryTipsText = '在应对较大的压力时，保持良好的情绪管理尤为重要。您可以尝试运动、冥想或瑜伽等减压方式来缓解情绪。适时放松也是关键，例如每周为自己安排一段时间进行户外活动或与亲友聚会，分享心情。通过分解学习或工作的任务，合理安排时间，避免让压力进一步积累。如果轻度抑郁的症状持续，建议您与心理咨询师沟通，帮助您有效管理情绪。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力非常大。尽管您没有焦虑，但高强度的压力和轻度抑郁情绪可能对您的生活和学习产生负面影响。';
          summaryTipsText = '在面对巨大压力时，建议您首先识别压力的具体来源，并尽量减少不必要的压力源。您可以每天留出时间做自己喜欢的事情，如看书、听音乐或与亲友聊天，这有助于缓解情绪和压力。适当减少工作或学习的负担，将大目标分解为小步骤进行。同时，保持规律的作息和健康的饮食也非常重要。如果轻度抑郁情绪没有改善，建议您寻求心理帮助。'
        } 
      } else if (depressionLevel == 3) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。您没有焦虑的症状，但中度抑郁的症状表明情绪上的问题需要更多关注和处理。';
          summaryTipsText = '中度抑郁的症状可能影响您的日常生活和学习表现。建议您每天尝试至少 30 分钟的运动，运动可以帮助改善情绪并释放压力。保持规律的生活作息，避免熬夜。建立一个情感支持系统，与家人或朋友保持定期沟通，能够帮助您表达情绪，获得更多支持。如果情绪持续低落，建议您尽早寻求心理治疗或咨询。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。您的压力水平较为适中，但中度抑郁的症状表明情绪上的问题需要更多关注和处理。';
          summaryTipsText = '中度抑郁的症状可能影响您的日常生活和学习表现。建议您每天尝试至少 30 分钟的运动，运动可以帮助改善情绪并释放压力。保持规律的生活作息，避免熬夜。建立一个情感支持系统，与家人或朋友保持定期沟通，能够帮助您表达情绪，获得更多支持。如果情绪持续低落，建议您尽早寻求心理治疗或咨询。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力与中度抑郁症状可能会对您的身心健康产生较大影响，需采取积极措施来应对。';
          summaryTipsText = '面对较大的压力和中度抑郁，建议您每天进行一些有助于调节情绪的活动，如冥想、瑜伽或散步。同时，定期与亲密的朋友或家人分享您的情绪，获得心理支持也是至关重要的。如果感到无力应对压力和情绪，建议尽早寻求专业心理帮助，接受心理咨询或心理治疗。通过适当的药物或心理干预，能有效缓解情绪低落的状态。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于无焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力很大。您的心理状态显示出较高的压力水平，同时伴随中度抑郁，需引起足够的重视，并尽快采取积极的调节和干预措施。';
          summaryTipsText = '在极大压力和中度抑郁的状态下，建议您采取一系列有效的缓解措施。首先，确保每晚有足够的休息时间，规律的作息能够提升您的情绪稳定性。同时，每天进行适当的体育锻炼，如跑步或游泳，能够帮助您释放压力、改善情绪。建议与心理咨询师讨论可能的应对策略，特别是如何有效减轻压力源。如果情绪和压力没有得到缓解，您可以考虑心理治疗或药物辅助治疗，以便更好地管理中度抑郁和高压力带来的影响。'
        } 
      } else if (depressionLevel == 4) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，表明没有焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。尽管您没有感到焦虑，且压力水平合适，但重度抑郁情绪表明您的心理健康状况需要重点关注。';
          summaryTipsText = '在重度抑郁的情况下，建议您首先建立规律的作息时间，确保每天有足够的睡眠和适量的运动。正念冥想、深呼吸训练等放松技巧可以帮助您缓解情绪低落。保持与朋友和家人的沟通，并寻求他们的支持。同时，建议您尽快寻求专业的心理帮助，通过心理治疗和药物辅助治疗，逐步改善情绪状态和心理健康，避免抑郁症状进一步恶化。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，表明没有焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力水平合适。尽管您没有感到焦虑，且压力水平适中，但重度抑郁情绪表明您的心理健康状况需要重点关注。';
          summaryTipsText = '在重度抑郁的情况下，建议您首先建立规律的作息时间，确保每天有足够的睡眠和适量的运动。正念冥想、深呼吸训练等放松技巧可以帮助您缓解情绪低落。保持与朋友和家人的沟通，并寻求他们的支持。同时，建议您尽快寻求专业的心理帮助，通过心理治疗和药物辅助治疗，逐步改善情绪状态和心理健康，避免抑郁症状进一步恶化。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，表明没有焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明您感受到较大的压力。虽然您目前没有明显的焦虑症状，但重度抑郁和较大的压力可能对您的情绪和身心健康造成严重影响，需立即关注。';
          summaryTipsText = '在面对重度抑郁和较大压力时，尽快减少压力源是关键。每天坚持做一些能够让自己放松的活动，如正念冥想、深呼吸或渐进性肌肉放松训练，能够帮助缓解情绪低落。规律的运动，如散步、瑜伽或慢跑，也有助于提升情绪。保持与亲友的沟通和联系，避免孤立自己，能获得情感上的支持。如果重度抑郁情绪持续存在，建议尽快寻求专业心理治疗，并考虑药物治疗，以确保情绪和生活状态得到及时的调节与改善。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，表明没有焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力非常大。虽然没有焦虑感，但重度抑郁加上极大的压力，可能对您的情绪、身体健康和生活质量带来严重影响。';
          summaryTipsText = '面对极大的压力和重度抑郁，建议您立即停止或减少生活中的主要压力源，并优先考虑您的心理健康。每天进行适量的运动和正念练习，如冥想、深呼吸训练，能有效帮助您缓解抑郁情绪并释放压力。保持与亲友或支持系统的联系，避免孤立自己非常重要。如果抑郁症状加重，建议立即寻求专业的心理支持和治疗，可能需要药物辅助治疗。通过心理治疗和积极的干预，您可以逐步恢复情绪的稳定，并提高生活质量。'
        } 
      }
    } else if (anxietyLevel == 2) {
      if (depressionLevel == 1) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。虽然您整体情绪良好，但偶尔可能会感到轻微的紧张和不安，需要适当的调节和放松。';
          summaryTipsText = '轻度焦虑通常与日常的压力源或生活变化相关。建议您保持规律的生活节奏，尝试进行深呼吸练习、正念冥想等放松技巧，帮助缓解焦虑情绪。同时，适量的运动、保持健康饮食以及足够的睡眠也有助于维持情绪稳定。与亲友交流和分享感受能缓解内心的压力，帮助您更好地适应日常生活中的挑战。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。虽然您整体情绪良好，但偶尔可能会感到轻微的紧张和不安，需要适当的调节和放松。';
          summaryTipsText = '轻度焦虑通常与日常的压力源或生活变化相关。建议您保持规律的生活节奏，尝试进行深呼吸练习、正念冥想等放松技巧，帮助缓解焦虑情绪。同时，适量的运动、保持健康饮食以及足够的睡眠也有助于维持情绪稳定。与亲友交流和分享感受能缓解内心的压力，帮助您更好地适应日常生活中的挑战。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。轻度焦虑加上较大的压力可能让您感到紧张不安，建议及时采取有效的压力管理策略。';
          summaryTipsText = '面对较大的压力和轻度焦虑，您可以通过合理安排日常任务、设定适度目标来减轻负担。保持定期的运动、如跑步或瑜伽，能够帮助您释放压力。建议每天抽出一些时间进行自我照顾和放松，如听音乐、散步或进行正念冥想。如果焦虑情绪持续影响日常生活，可以考虑寻求专业的心理帮助，预防焦虑加重。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力非常大。高压力水平加上轻度焦虑，可能会给您的身心带来负面影响，需及时调节和管理。';
          summaryTipsText = '当感受到压力非常大时，建议您通过定期休息、减轻工作或学习负担来缓解压力源。适时进行放松技巧，如深呼吸、冥想或渐进性肌肉放松法，有助于释放积累的压力和焦虑情绪。与朋友或家人倾诉内心感受，获得情感支持也能帮助您应对高压力带来的负面情绪。如果焦虑持续加重或影响日常生活，建议及时寻求心理专业的帮助。'
        } 
      } else if (depressionLevel == 2) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。虽然您的压力水平合适，但轻度的焦虑和抑郁情绪可能让您时常感到内心不安或情绪低落。';
          summaryTipsText = '面对轻度的焦虑和抑郁，建议您每天留出时间进行自我放松和调节，如参加运动、听音乐或进行正念冥想。保持与朋友和家人沟通，分享您的感受，这有助于缓解负面情绪。适当的户外活动也能帮助您提升情绪。如果轻度焦虑和抑郁情绪持续，建议考虑与心理咨询师交流，制定针对性的情绪管理方案。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。虽然您的压力水平合适，但轻度的焦虑和抑郁情绪可能让您时常感到内心不安或情绪低落。';
          summaryTipsText = '面对轻度的焦虑和抑郁，建议您每天留出时间进行自我放松和调节，如参加运动、听音乐或进行正念冥想。保持与朋友和家人沟通，分享您的感受，这有助于缓解负面情绪。适当的户外活动也能帮助您提升情绪。如果轻度焦虑和抑郁情绪持续，建议考虑与心理咨询师交流，制定针对性的情绪管理方案。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力加上轻度焦虑和轻度抑郁情绪，可能会加剧内心的不安和情绪波动。';
          summaryTipsText = '在这种情况下，压力管理显得尤为重要。建议您每天进行 30 分钟左右的运动，以帮助释放压力和提高情绪稳定性。通过设定合理的工作或学习计划，避免过度负担的积累。您也可以尝试通过写日记或艺术创作表达内心情感，作为释放情绪的方式。如果发现情绪状况持续恶化，建议尽早寻求心理咨询师的帮助，避免情绪问题进一步发展。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '表明压力非常大。高压力水平加上轻度焦虑和轻度抑郁，可能会对您的情绪和日常生活产生明显的负面影响。';
          summaryTipsText = '面对极大的压力，建议您首先识别主要的压力来源，并通过适当的任务管理和时间分配来减轻压力。同时，定期进行身体锻炼和放松练习（如正念冥想、深呼吸）能帮助您减缓焦虑和抑郁情绪。保持与亲友的沟通交流，获得支持和理解也是非常有效的缓解方法。如果发现焦虑和抑郁情绪持续加重，建议及时寻求专业心理帮助，制定个性化的干预方案。'
        } 
      } else if (depressionLevel == 3) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。尽管压力水平合适，但中度抑郁症状可能对您的情绪产生较大的影响，同时轻度焦虑可能加重您的不安感。';
          summaryTipsText = '中度抑郁通常会影响日常生活的情绪状态。建议您首先制定合理的生活作息安排，确保充足的睡眠和均衡的饮食，这有助于改善情绪。同时，定期进行有氧运动如散步或慢跑，不仅可以减轻焦虑情绪，还能缓解抑郁症状。如果抑郁和焦虑情绪难以改善，建议您寻求心理咨询师的帮助，开展适当的心理干预。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。尽管压力水平合适，但中度抑郁症状可能对您的情绪产生较大的影响，同时轻度焦虑可能加重您的不安感。';
          summaryTipsText = '中度抑郁通常会影响日常生活的情绪状态。建议您首先制定合理的生活作息安排，确保充足的睡眠和均衡的饮食，这有助于改善情绪。同时，定期进行有氧运动如散步或慢跑，不仅可以减轻焦虑情绪，还能缓解抑郁症状。如果抑郁和焦虑情绪难以改善，建议您寻求心理咨询师的帮助，开展适当的心理干预。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力可能会加剧中度抑郁和轻度焦虑的症状，影响日常情绪和生活。';
          summaryTipsText = '在压力较大的情况下，建议您尝试通过分解任务、设定优先级来减轻压力负担。每天保持适量的运动，如瑜伽或快走，能够有效缓解焦虑和抑郁情绪。尝试与信任的朋友或家人交流，分享您的感受，获得心理支持。如果情绪难以改善或加重，建议尽快寻求心理咨询师的帮助，进行系统的心理治疗或药物辅助治疗。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力很大。极大的压力可能导致焦虑和抑郁症状进一步加重，对生活和学习造成负面影响。';
          summaryTipsText = '面对极大压力和情绪困扰，建议您尽快采取行动进行压力管理。您可以通过深呼吸、正念冥想等放松练习来调节情绪，缓解压力。同时，建议您将日常任务分解成可操作的小步骤，避免一次性承担过多责任。如果抑郁和焦虑情绪难以缓解，建议您及时寻求心理治疗，获得专业的心理干预，以防情绪问题进一步发展。'
        } 
      } else if (depressionLevel == 4) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。虽然您的压力水平相对适中，但重度抑郁和轻度焦虑情绪已经对您的生活产生了明显的影响，需要立即关注并进行干预。';
          summaryTipsText = '重度抑郁情绪需要及时干预，以避免对生活和心理健康造成更大的影响。建议您首先保持规律的生活作息，确保足够的睡眠，并通过每日运动来缓解情绪波动。如果焦虑和抑郁情绪没有得到有效缓解，建议您尽快寻求心理治疗，并考虑药物治疗的支持。在专业人士的帮助下，制定个性化的心理治疗计划，有助于更好地管理和应对抑郁和焦虑情绪。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。虽然您的压力水平相对适中，但重度抑郁和轻度焦虑情绪已经对您的生活产生了明显的影响，需要立即关注并进行干预。';
          summaryTipsText = '重度抑郁情绪需要及时干预，以避免对生活和心理健康造成更大的影响。建议您首先保持规律的生活作息，确保足够的睡眠，并通过每日运动来缓解情绪波动。如果焦虑和抑郁情绪没有得到有效缓解，建议您尽快寻求心理治疗，并考虑药物治疗的支持。在专业人士的帮助下，制定个性化的心理治疗计划，有助于更好地管理和应对抑郁和焦虑情绪。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力加上重度抑郁和轻度焦虑，可能会显著影响您的日常生活和心理状态';
          summaryTipsText = '面对较大的压力和情绪困扰，建议您首先采取行动减轻学习或生活中的负担，如将工作任务分解为更易处理的小步骤。同时，每天留出时间进行自我照顾，参与放松的活动，如正念冥想或听音乐等。重度抑郁需要特别关注，建议您尽早寻求心理治疗或药物治疗支持，确保情绪问题得到有效管理。与信任的人保持沟通，获得情感支持，也能帮助您缓解心理压力。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力很大。极大的压力水平加上轻度焦虑和重度抑郁，表明您的心理状态需要立即干预和重视。';
          summaryTipsText = '在面对如此大的压力时，首先要识别并减少压力源，避免过度负担。建议每天坚持做一些有助于情绪平稳的活动，如运动、瑜伽、冥想等，以帮助缓解焦虑和抑郁。重度抑郁是需要尽快干预的心理问题，建议您及时寻求专业心理咨询或心理治疗，并可能需要考虑药物治疗。与亲友保持沟通，获得情感支持，有助于减轻孤独感和压力。'
        } 
      }
    } else if (anxietyLevel == 3) {
      if (depressionLevel == 1) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。虽然您整体情绪良好，但中度焦虑情绪可能让您感到内心紧张和不安，需要适当的调节与管理。';
          summaryTipsText = '中度焦虑可能源于生活中的某些不确定性或未来的压力，建议您每天留出时间进行放松练习，如正念冥想、深呼吸或渐进性肌肉放松法。规律的运动（如跑步或瑜伽）也可以帮助缓解焦虑。同时，保持与朋友和家人间的联系，倾诉您的感受，能有效降低焦虑。如果焦虑情绪持续影响日常生活，建议与心理咨询师探讨进一步的管理策略。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。虽然压力合适，但中度焦虑情绪可能让您感到内心紧张和不安，需要适当的调节与管理。';
          summaryTipsText = '中度焦虑可能源于生活中的某些不确定性或未来的压力，建议您每天留出时间进行放松练习，如正念冥想、深呼吸或渐进性肌肉放松法。规律的运动（如跑步或瑜伽）也可以帮助缓解焦虑。同时，保持与朋友和家人间的联系，倾诉您的感受，能有效降低焦虑。如果焦虑情绪持续影响日常生活，建议与心理咨询师探讨进一步的管理策略。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。中度焦虑加上较大的压力，可能会对您的心理状态造成更大的负担。';
          summaryTipsText = '面对较大的压力和焦虑情绪，建议您首先制定合理的时间管理计划，将繁重的任务分散到多个阶段完成，以减轻压力。适当的休息、定期运动和参加放松的活动（如听音乐、阅读）都能帮助您平复焦虑情绪。与朋友、家人或心理咨询师定期交流，分享内心感受，能有效缓解压力并帮助您找到新的应对策略。如果焦虑持续影响日常生活，建议尽早寻求心理帮助。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力很大。巨大的压力可能加剧您的焦虑情绪，需采取积极的压力管理和情绪调节策略。';
          summaryTipsText = '面对极大压力和中度焦虑，建议您尽快识别压力源，采取具体措施来减少压力的影响。您可以通过每天的运动、深呼吸练习或正念冥想来帮助缓解压力。此外，确保每天留出时间进行放松，避免过度疲劳和情绪积压。建议与心理咨询师讨论进一步的焦虑管理策略，如果情绪难以缓解，专业心理支持是非常必要的。'
        } 
      } else if (depressionLevel == 2) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。虽然您的压力水平合适，但中度焦虑和轻度抑郁可能让您感受到情绪波动和轻度的低落情绪。';
          summaryTipsText = '中度焦虑和轻度抑郁需要及时调节，避免情绪持续恶化。建议您定期参加放松活动，如听音乐、阅读、户外散步或艺术创作，这些活动能有效提升情绪。同时，保持与亲友的沟通，分享您的情绪感受。规律的作息、适量的运动和健康饮食也对缓解焦虑和抑郁情绪非常有帮助。如果情绪持续影响生活，建议您考虑寻求心理咨询师的支持。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。尽管压力水平适中，但中度焦虑和轻度抑郁可能让您感受到情绪波动和轻度的低落情绪。';
          summaryTipsText = '中度焦虑和轻度抑郁需要及时调节，避免情绪持续恶化。建议您定期参加放松活动，如听音乐、阅读、户外散步或艺术创作，这些活动能有效提升情绪。同时，保持与亲友的沟通，分享您的情绪感受。规律的作息、适量的运动和健康饮食也对缓解焦虑和抑郁情绪非常有帮助。如果情绪持续影响生活，建议您考虑寻求心理咨询师的支持。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力加上焦虑和轻度抑郁情绪，可能对您的生活和情绪产生负面影响。';
          summaryTipsText = '建议您采取积极的压力管理措施，避免情绪持续加重。通过将工作或学习任务分解为较小的步骤，可以帮助您减轻过度的压力感。规律的运动和定期的放松活动，如瑜伽或冥想，也有助于缓解焦虑和抑郁。同时，与朋友、家人或心理咨询师讨论您的情绪问题，获得适时的支持和理解。如果发现情绪持续波动，建议尽早寻求心理治疗。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '表明压力很大。极大的压力加上中度焦虑和轻度抑郁，可能严重影响您的情绪和心理状态。';
          summaryTipsText = '在如此高的压力下，建议您优先采取压力缓解措施。您可以通过深呼吸、渐进性肌肉放松和正念冥想等方式帮助缓解焦虑情绪。同时，确保每天进行适度的运动，如跑步或健身，以释放积累的压力和负面情绪。如果焦虑和抑郁情绪持续加重，建议尽早寻求心理咨询师的支持，避免长期情绪问题对生活和健康造成更大的影响。'
        } 
      } else if (depressionLevel == 3) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。尽管压力水平合适，但中度焦虑和中度抑郁可能让您感受到情绪低落和内心紧张。';
          summaryTipsText = '中度焦虑和抑郁情绪需要积极管理。建议您每天保持规律的生活作息，避免过度劳累，同时进行定期的运动，如慢跑或瑜伽，以帮助情绪释放。与朋友和家人保持情感沟通，获得情感支持也非常重要。如果发现自己情绪持续低落，建议尽早寻求心理治疗或心理咨询师的帮助，以便有效管理焦虑和抑郁。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。尽管压力水平适中，但中度焦虑和中度抑郁可能让您感受到情绪低落和内心紧张。';
          summaryTipsText = '中度焦虑和抑郁情绪需要积极管理。建议您每天保持规律的生活作息，避免过度劳累，同时进行定期的运动，如慢跑或瑜伽，以帮助情绪释放。与朋友和家人保持情感沟通，获得情感支持也非常重要。如果发现自己情绪持续低落，建议尽早寻求心理治疗或心理咨询师的帮助，以便有效管理焦虑和抑郁。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力加上中度焦虑和中度抑郁，可能对您的情绪和心理健康造成较大的困扰。';
          summaryTipsText = '当中度焦虑和抑郁伴随较大压力时，建议您积极进行压力管理。通过分解任务、合理规划时间，可以有效减轻日常生活中的负担。规律的运动、充足的睡眠和适当的放松活动，如冥想或深呼吸练习，能帮助您缓解情绪。与亲友保持联系，获得情感支持，也有助于您应对压力。如果情绪波动持续或加剧，建议尽早寻求专业心理帮助。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力很大。高压力水平加上中度焦虑和中度抑郁，可能严重影响您的日常生活和心理健康。';
          summaryTipsText = '在压力非常大的情况下，中度焦虑和抑郁情绪会进一步加剧负面影响。建议您首先识别并减少主要压力源，避免不必要的负担。通过定期的运动、深呼吸和正念冥想来调节情绪，减轻压力。同时，保持与朋友、家人的联系，寻求他们的支持也有助于缓解孤独感。如果情绪问题持续加剧，建议尽早寻求心理咨询师的帮助，进行专业的心理干预。'
        } 
      } else if (depressionLevel == 4) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。虽然您的压力水平相对适中，但重度抑郁加上中度焦虑可能对您的心理健康带来严重影响，需要及时干预。';
          summaryTipsText = '面对重度抑郁和中度焦虑，建议您尽早采取措施缓解情绪问题。首先，建立规律的作息时间，确保足够的睡眠和合理的饮食是基础。日常运动、如散步或瑜伽，能够帮助减轻焦虑和提升情绪。如果您发现情绪难以自行调节，建议立即寻求专业的心理帮助，并可能需要考虑药物治疗的支持。通过专业治疗和自我管理相结合，能有效帮助您缓解情绪困扰。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。尽管您的压力水平适中，但重度抑郁加上中度焦虑可能对您的心理健康带来严重影响，需要及时干预。';
          summaryTipsText = '面对重度抑郁和中度焦虑，建议您尽早采取措施缓解情绪问题。首先，建立规律的作息时间，确保足够的睡眠和合理的饮食是基础。日常运动、如散步或瑜伽，能够帮助减轻焦虑和提升情绪。如果您发现情绪难以自行调节，建议立即寻求专业的心理帮助，并可能需要考虑药物治疗的支持。通过专业治疗和自我管理相结合，能有效帮助您缓解情绪困扰。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力水平加上中度焦虑和重度抑郁，可能显著影响您的情绪和身心健康。';
          summaryTipsText = '重度抑郁需要及时的干预和管理。建议您尽快采取压力管理措施，避免情绪问题进一步恶化。每天的运动、放松练习如冥想或深呼吸能够有效缓解焦虑和抑郁情绪。寻求专业心理支持是非常重要的，心理咨询或心理治疗能够帮助您更好地理解和应对情绪困扰。家人和朋友的支持也能够在这段时间内给予您情感上的支撑，缓解孤独和无助感。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于中度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力非常大。极大的压力水平加上重度抑郁和中度焦虑，表明您的心理健康状态需要立即干预。';
          summaryTipsText = '在面对如此高的压力和情绪问题时，建议您首先设法减少日常负担，识别并处理主要的压力源。您可以尝试通过运动、呼吸练习和正念冥想来缓解焦虑情绪，同时确保充足的休息时间和健康的饮食。重度抑郁是需要高度重视的心理问题，建议您尽快寻求专业心理咨询，并可能需要药物治疗。与亲友保持情感上的联系，也能为您提供额外的支持和理解，帮助您更好地应对心理挑战。'
        } 
      }
    } else if (anxietyLevel == 4) {
      if (depressionLevel == 1) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。虽然您整体情绪良好，但重度焦虑可能让您感受到显著的内心紧张和不安，需要及时调节。';
          summaryTipsText = '重度焦虑会对您的生活质量产生较大影响，建议您尽早采取措施进行焦虑管理。您可以通过设定合理的生活和学习目标，避免不必要的负担。正念冥想、深呼吸练习等放松技巧能够帮助您缓解焦虑情绪。定期运动，如慢跑或游泳，也有助于平稳情绪。如果焦虑情绪持续影响您的日常生活，建议您尽快寻求心理咨询或心理治疗的帮助。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。虽然您的压力水平适中，但重度焦虑可能让您感受到显著的内心紧张和不安，需要及时调节。';
          summaryTipsText = '重度焦虑会对您的生活质量产生较大影响，建议您尽早采取措施进行焦虑管理。您可以通过设定合理的生活和学习目标，避免不必要的负担。正念冥想、深呼吸练习等放松技巧能够帮助您缓解焦虑情绪。定期运动，如慢跑或游泳，也有助于平稳情绪。如果焦虑情绪持续影响您的日常生活，建议您尽快寻求心理咨询或心理治疗的帮助。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力水平加上重度焦虑，可能对您的身心健康产生较为严重的影响。';
          summaryTipsText = '在面对较大压力和重度焦虑时，建议您首先减少日常压力源，避免过度负担。通过定期运动、正念冥想和放松训练来帮助减轻焦虑情绪，同时保持与朋友、家人的沟通，以获取情感支持。如果焦虑持续或加重，建议尽早寻求心理咨询，探索进一步的情绪管理策略，确保焦虑情绪得到有效缓解。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，属于无抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力很大。极大的压力水平加上重度焦虑，可能会对您的心理健康和生活质量造成显著影响。';
          summaryTipsText = '极大的压力和重度焦虑需要紧急的应对措施。建议您尽快减少主要压力源，避免情绪和压力的积累。每天进行适量的运动、冥想和深呼吸练习能有效缓解压力和焦虑。同时，保持与亲友的沟通，通过情感支持来缓解内心的孤独感。如果焦虑和压力持续影响您的生活，建议您立即寻求专业心理支持，以便有效管理情绪问题。'
        } 
      } else if (depressionLevel == 2) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。虽然您的压力水平合适，但重度焦虑和轻度抑郁可能让您感到心理负担较大，需要尽早调节。';
          summaryTipsText = '在重度焦虑和轻度抑郁的状态下，建议您首先设立明确的生活和学习目标，避免过多的负担。通过每天的运动、放松技巧如正念冥想或深呼吸练习来帮助缓解焦虑情绪。与亲友保持联系，倾诉您的感受，获得情感支持。必要时，您可以寻求专业的心理咨询师帮助，制定适合的情绪管理策略，以缓解焦虑和抑郁情绪对生活的影响。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。虽然压力水平适中，但重度焦虑和轻度抑郁可能让您感到心理负担较大，需要尽早调节。';
          summaryTipsText = '在重度焦虑和轻度抑郁的状态下，建议您首先设立明确的生活和学习目标，避免过多的负担。通过每天的运动、放松技巧如正念冥想或深呼吸练习来帮助缓解焦虑情绪。与亲友保持联系，倾诉您的感受，获得情感支持。必要时，您可以寻求专业的心理咨询师帮助，制定适合的情绪管理策略，以缓解焦虑和抑郁情绪对生活的影响。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力水平加上重度焦虑和轻度抑郁，可能对您的生活和情绪产生较大的负面影响。';
          summaryTipsText = '在这种状态下，建议您首先识别主要的压力来源，并通过合理的时间管理来减轻负担。适时进行放松训练（如冥想或深呼吸），能有效缓解焦虑情绪。同时，每天坚持适量的运动，确保足够的睡眠，以帮助身体和心灵恢复。如果焦虑和抑郁持续影响生活，建议及时寻求心理治疗，制定个性化的情绪管理方案。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有轻度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '表明压力很大。极大的压力加上重度焦虑和轻度抑郁，可能对您的身心健康造成较大威胁，需要立即采取行动。';
          summaryTipsText = '极大的压力和重度焦虑需要紧急的处理措施。建议您首先设法减少不必要的压力来源，避免过度负担。通过运动、正念冥想和深呼吸训练等放松技巧，帮助您缓解情绪。同时，确保每天有充足的休息和放松时间，避免过度劳累。如果焦虑和抑郁情绪持续加重，建议您及时寻求心理支持，确保您的情绪问题得到有效处理和管理。'
        } 
      } else if (depressionLevel == 3) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。尽管压力水平合适，但重度焦虑和中度抑郁可能显著影响您的情绪和心理健康。';
          summaryTipsText = '建议您首先建立规律的生活习惯和作息时间，确保充足的睡眠和均衡的饮食，这对情绪管理非常重要。每天进行适度的运动，如慢跑、瑜伽，能有效帮助缓解焦虑情绪。与朋友、家人保持沟通，通过情感支持来释放压力。如果重度焦虑和中度抑郁持续影响您的生活，建议您尽快寻求专业心理治疗，以获得全面的情绪管理支持。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。尽管压力水平适中，但重度焦虑和中度抑郁可能显著影响您的情绪和心理健康。';
          summaryTipsText = '建议您首先建立规律的生活习惯和作息时间，确保充足的睡眠和均衡的饮食，这对情绪管理非常重要。每天进行适度的运动，如慢跑、瑜伽，能有效帮助缓解焦虑情绪。与朋友、家人保持沟通，通过情感支持来释放压力。如果重度焦虑和中度抑郁持续影响您的生活，建议您尽快寻求专业心理治疗，以获得全面的情绪管理支持。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力加上重度焦虑和中度抑郁，可能对您的心理健康带来较大的负面影响。';
          summaryTipsText = '面对较大的压力和重度焦虑，您需要首先识别并减少不必要的压力源，通过合理的任务规划减轻压力。同时，每天进行 30 分钟左右的运动，并留出时间进行放松活动，如正念冥想或深呼吸练习，帮助缓解情绪波动。如果重度焦虑和中度抑郁持续影响您的生活，建议您及时寻求心理治疗，预防问题进一步加重。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有中度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力很大。极大的压力加上重度焦虑和中度抑郁，可能对您的心理和身体健康带来严重的负面影响，需要尽快采取干预措施。';
          summaryTipsText = '在如此高的压力水平下，建议您立即采取行动，减少主要的压力源，避免情绪进一步恶化。通过每天的运动、呼吸练习和正念冥想来帮助释放焦虑情绪，并确保足够的休息时间。重度焦虑和中度抑郁的结合表明需要专业的心理干预，建议您尽快与心理咨询师沟通，并可能需要药物治疗以稳定情绪。'
        } 
      } else if (depressionLevel == 4) {
        if (pressureLevel == 1) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力偏低。虽然您的压力水平相对适中，但重度焦虑和重度抑郁可能会对您的生活质量和心理健康产生严重影响。';
          summaryTipsText = '重度焦虑和重度抑郁是需要高度重视的心理问题，建议您尽快寻求专业的心理帮助。通过建立规律的作息和饮食习惯，确保每天有足够的睡眠和适量的运动，能帮助您改善情绪状态。正念冥想、深呼吸练习等放松技巧能帮助您缓解焦虑情绪。如果情绪持续恶化，建议考虑心理治疗和药物治疗的结合，以确保您的心理健康得到全面的管理。'
        } else if (pressureLevel == 2) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力合适。虽然您的压力水平适中，但重度焦虑和重度抑郁可能会对您的生活质量和心理健康产生严重影响。';
          summaryTipsText = '重度焦虑和重度抑郁是需要高度重视的心理问题，建议您尽快寻求专业的心理帮助。通过建立规律的作息和饮食习惯，确保每天有足够的睡眠和适量的运动，能帮助您改善情绪状态。正念冥想、深呼吸练习等放松技巧能帮助您缓解焦虑情绪。如果情绪持续恶化，建议考虑心理治疗和药物治疗的结合，以确保您的心理健康得到全面的管理。'
        } else if (pressureLevel == 3 || pressureLevel == 4) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力较大。较大的压力水平加上重度焦虑和重度抑郁，可能对您的情绪和心理健康带来较大的负面影响，需要及时干预。';
          summaryTipsText = '建议您首先通过减少不必要的任务和负担来缓解压力源，同时保持每天的放松练习和适量的运动。面对重度焦虑和抑郁，及时寻求专业心理治疗是非常重要的，通过与心理咨询师的合作，制定个性化的治疗方案，您可以更好地管理这些情绪问题。通过积极的压力管理和情感支持，能有效帮助您缓解负面情绪和心理困扰。'
        } else if (pressureLevel == 5) {
          summaryText = '您在焦虑自评量表上的得分为' + anxietyScore + '分，属于重度焦虑；在抑郁自评量表上的得分为' + depressionScore + '分，表明有重度抑郁；您的学习生活压力得分为 ' + data?.pressureScore + '，表明压力很大。极大的压力加上重度焦虑和重度抑郁，可能严重影响您的心理和身体健康，需要立即采取有效的干预措施。';
          summaryTipsText = '在这种极端压力下，建议您首先停止或减少压力源，并寻求身边的支持系统，避免孤立自己。每天坚持进行运动和放松练习，如正念冥想、呼吸训练等，有助于缓解焦虑情绪。面对重度焦虑和重度抑郁，建议您立即寻求专业心理治疗，并可能需要药物辅助治疗。通过积极的干预和治疗，能帮助您有效管理压力和情绪，逐步恢复心理健康。'
        } 
      }
    }


    return (
      <div className="viewCISCReportPage">
        <div className="textQuestion" >Report</div>
        <div className="textQuestion" >{data?.createtime}</div>
        <div className="textAnxiety">{anxietyText}</div>
        <div className="textAnxiety">{depressionText}</div>
        <div className="textPressure">{pressureText}</div>
        <div className="textSatisfaction">{satisfactionText}</div>
        <div className="textSatisfaction">{summaryText}</div>
        <div className="textSatisfaction">{summaryTipsText}</div>

      </div>
    );
  }

}